/* (c) George Dantens | dantens.com | george@dantens.com */


$(document).ready(function() {

/* appearance */
setTimeout(function() {
    $('#layout').addClass('active');
}, 500);
	
/* nav-achors */
$('.nav a[data-href]').click(function(e) {
    e.preventDefault();
    
    var $this = $(this).attr('href');
    
    $('html, body').animate({
        scrollTop: $($this).offset().top
    }, 1000);
});
	
/* nav-by-section */
$(window).scroll(function() {
    var scroll = $(window).scrollTop(),
        w = $(window).height(),
        wh = w/2,
        dhref = $(this).data('href');
    
    if (scroll) {
        $('.section').each(function(i) {
            var id = $(this).attr('id');
            
            if ($(this).position().top <= scroll + w - wh) {
                $('.nav li.nav-item a').removeClass('active');
                $('.nav li.nav-item a[data-href="' + id + '"]').addClass('active');
                $('.section.active').removeClass('active');
                $(this).addClass('active acted');
            }
        });
    } else {
        $('.section.active').removeClass('active');
    }
});

/* dropdown */
$('.dropdown').each(function() {
    $('.dropdown-text').click(function() {
        if (!($(this).hasClass('dropdown-open'))){
            $(this).parent().addClass('dropdown-open');
        }
    });
    $('.dropdown-list li').click(function() {
        var dval = $(this).attr('data-val'),
            dvalt = $(this).text();
        $(this).addClass('selected').siblings().removeClass('selected');
        dinput = $(this).parents('.dropdown').find('input');
        dtext = $(this).parents('.dropdown').find('.dropdown-text');
        dtext.text(dvalt);
        dinput.val(dval);
        dinput.change();
        $('.dropdown.dropdown-open').removeClass('dropdown-open');
    });
});
$(document).mouseup(function(e) {
    if ($('.dropdown-list').is(':visible')) {
        if (!$(e.target).closest('.dropdown-list').length) {
            $('.dropdown.dropdown-open').removeClass('dropdown-open');
        }
    }
});
$('.filter input').change(function() {
    $('.filter input').each(function() {
        var data = $(this).data('part'),
            val = this.value;
        if (val != '') {
            $('.reward-part[data-part*="' + data + '"]').removeClass('visible');
            $('.reward-part[data-prop*="' + val + '"]').addClass('visible');
        }
    });
});

/* popup */  
function openPopup(){
    $('.popup').addClass('popup-opened');
    $('.popup-content-' + dpopup).addClass('popup-content-opened popup-content-hidden');
    $('body').addClass('no-scroll');
}
function closePopup(){
    $('body').removeClass('no-scroll');
    $('.popup-content').removeClass('popup-content-opened');
    setTimeout(function() {
        $('.popup-content').removeClass('popup-content-hidden');
    }, 500);
    $('.popup').removeClass('popup-opened');
}
$('.open-popup').click(function(e){
    e.preventDefault();
    var dpopup=$(this).data('popup');
    $('.popup').addClass('popup-opened');
    $('.popup-content-' + dpopup).addClass('popup-content-opened popup-content-hidden');
    $('body').addClass('no-scroll');
});
$('.popup-content-download').on('click', '.change-popup', function(e){
    e.preventDefault();
    $('.popup-content-download').removeClass('popup-content-opened popup-content-hidden');
    $('.popup-content-android').addClass('popup-content-opened popup-content-hidden');
});
$('.popup .popup-close').click(function(){
    closePopup();
});
$(document).mouseup(function(e){
    if($('.popup-content').is(':visible')){
        if(!$(e.target).closest('.popup-content').length){
            closePopup();
        }
    }
});

/* mobile-nav */
function openNav(){
    $('#header').addClass('header-opened');
	$('#subnav').addClass('header-opened');
    $('.header-nav-toggle').addClass('active');
    $('body').addClass('no-scroll');
}
function closeNav(){
    $('body').removeClass('no-scroll');
    $('.header-nav-toggle').removeClass('active');
	$('#subnav').removeClass('header-opened');
    $('#header').removeClass('header-opened');
}
$('.header-nav-toggle').click(function(){
  if(!$(this).hasClass('active')){ openNav(); }
  else { closeNav(); }
});
$('.nav > ul > li > a').click(function(){
  closeNav();
});
});